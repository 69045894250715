<template>
  <div class="container mt-5" style="text-align: center">
    <h1>Fit Financial Toolkit</h1>
    <p>
      A clean and simple Bruto Netto and Verantwoord Krediet API by Fit Solutions B.V.
    </p>

    <a href="/docs/index.html" target="_self">View OpenAPI spec</a>

    <br />

    Register for a new account? <a href="mailto:info@fitsolutions.nl?subject=FTK Account">Contact us at info@fitsolutions.nl</a>

  </div>
</template>

<script>
// @ is an alias to /src


export default {
  name: 'Home',
  data() {
    return {
      version: null
    }
  },
  components: {},
  mounted() {
  }
}
</script>
